.bigloader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .spinner-container {
    position: relative;
    width: 200px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
  }
  
  .central-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    animation: spin 2s linear infinite;
  }
  
  .spinner-dot {
    position: absolute;
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
    border-radius: 50%;
  }
  
  .spinner-dot:nth-child(1) {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFD700;
  }
  
  .spinner-dot:nth-child(2) {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    background-color: white;
  }
  
  .spinner-dot:nth-child(3) {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #379B07;
  }
  
  .spinner-dot:nth-child(4) {
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    background-color: #8B0000;
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  